import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";
import { Permission } from "@/core/shared/helpers/Permission";

export default [
  {
    path: "/creator",
    children: [
      {
        path: "/creators",
        name: "TheCreators",
        component: () => import("@/modules/creator/views/TheCreators.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.creator.creators.meta.title",
          permission: Permission.ManageCreator,
        },
      },
      {
        path: "/creators/top",
        name: "TopCreators",
        component: () => import("@/modules/creator/views/TopCreators.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.creator.topCreators.meta.title",
          permission: Permission.ManageCreator,
        },
      },
      {
        path: ":id/edit",
        name: "EditCreator",
        component: () => import("@/modules/creator/views/CreatorEdit.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.creator.edition.meta.title",
          permission: Permission.ManageCreator,
        },
      },
      {
        path: ":id",
        name: "CreatorSummary",
        component: () => import("@/modules/creator/views/CreatorSummary.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.creator.summary.meta.title",
          permission: Permission.ManageCreator,
        },
      },
      {
        path: ":id/approval",
        name: "CreatorApproval",
        component: () => import("@/modules/creator/views/CreatorApproval.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.creator.approval.meta.title",
          permission: Permission.ManageCreator,
        },
      },
      {
        path: ":id/wallet",
        name: "CreatorWallet",
        component: () => import("@/modules/creator/views/CreatorWallet.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.wallet.creator.meta.title",
          permission: Permission.ManageAdmin,
        },
      },
      {
        path: "pending",
        name: "PendingCreators",
        component: () => import("@/modules/creator/views/PendingCreators.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "common.route.pendingCreators.meta.title",
          permission: Permission.ManageCreator,
        },
      },
      {
        path: ":id/amazon/approval",
        name: "AmazonInfluencerApproval",
        component: () =>
          import("@/modules/creator/views/AmazonInfluencerApproval.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "modules.creator.approval.meta.title",
          permission: Permission.ManageCreator,
        },
      },
      {
        path: "pending-amazon",
        name: "PendingAmazonInfluencers",
        component: () =>
          import("@/modules/creator/views/PendingAmazonInfluencers.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "common.route.pendingAmazonInfluencers.meta.title",
          permission: Permission.ManageCreator,
        },
      },
      {
        path: ":id/payment-approval",
        name: "PaymentApproval",
        component: () => import("@/modules/creator/views/PaymentApproval.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "common.route.creatorPaymentApproval.meta.title",
          permission: Permission.ManageCreator,
        },
      },
      {
        path: "payments-approval",
        name: "PaymentsApproval",
        component: () => import("@/modules/creator/views/PaymentsApproval.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "common.route.creatorPayments.meta.title",
          permission: Permission.ManageCreator,
        },
      },
    ],
  },
];
