import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";
import { Permission } from "@/core/shared/helpers/Permission";

export default [
  {
    path: "/tiktok-ads",
    beforeEnter: AuthGuard.withToken,
    children: [
      {
        path: "",
        name: "TikTokAds",
        component: () => import("@/modules/tiktok-ad/views/TikTokAds.vue"),
        meta: {
          title: "common.route.tiktokAds.meta.title",
          permission: Permission.ManageTikTokAd,
        },
        beforeEnter: AuthGuard.withToken,
      },
      {
        path: ":id/approval",
        name: "TikTokAdApproval",
        component: () =>
          import("@/modules/tiktok-ad/views/TikTokAdApproval.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "common.route.tiktokAdApproval.meta.title",
          permission: Permission.ManageTikTokAd,
        },
      },
      {
        path: "pending",
        name: "PendingTikTokAds",
        component: () =>
          import("@/modules/tiktok-ad/views/PendingTikTokAds.vue"),
        beforeEnter: AuthGuard.withToken,
        meta: {
          title: "common.route.pendingTikTokAds.meta.title",
          permission: Permission.ManageTikTokAd,
        },
      },
    ],
  },
];
